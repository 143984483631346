@import "media.less";
@import "colors.less";

html {
  font-size: 14px;
}

.v-toolbar__title {
  display: flex;

  img {
    height: 32px;
    margin-right: 15px;
  }
}

#app {
  background-color: #ddd;
}

.theme--light.v-sheet {
  color: @textColor;
}

#ads {
  margin: -5px 0 10px 0;

  a {
    display: block;
    margin: 0 auto;
    background-color: white;
    width: 100%;
    max-width: 230px;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 230px;
  }
}

.v-card {
  margin-bottom: 30px;
}

@media @for-phone {
  .container {
    padding: 16px 0 !important;
  }
}

.source-link {
  text-align: right;
  font-size: 0.9rem;
  padding: 0 20px 20px 0;
}

.v-tabs__div {
  img {
    height: 24px;
  }
}

.container {
  max-width: 1024px !important;
}

.loader {
  text-align: center;
  width: 100%;
}

.share {
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: @menuColor;
  }

  img {
    opacity: .54;
    height: 24px;
    width: 24px;
  }
}

.theme--light.v-card .v-card__subtitle, .theme--light.v-card>.v-card__text {
  color: rgba(0,0,0,.75);
}

.v-card__text {
  font-size: inherit;
}

.v-subheader {
  font-weight: 500;
}

.small-break {
  display: none;

  @media @for-phone {
    display: block;
  }
}

.events-page {
  h3 {
    margin-top: 30px;
    font-size: 1.3rem;
  }

  li {
    padding: 6px 0;
    font-size: 1.3rem;
  }

  .v-card__text {
    display: flex;
    flex-wrap: wrap;

    & > div {
      max-width: 50%;
      min-width: 260px;
      padding: 0 12px 10px 12px;
    }
  }
}

@import "ios.less";


html.native {
  background-color: white;
}

body.native {
  background-color: white;

  .notch {
    width: 100vw;
    background-color: white;
    position: fixed;
    top: 0;
    height: env(safe-area-inset-top) !important;
    z-index: 1000;

    @media (prefers-color-scheme: dark) {
      background-color: #222;
    }

    @media (orientation: landscape) {

    }
  }

  .notch2 {
    width: 100vw;
    background-color: white;
    position: fixed;
    bottom: 0;
    height: env(safe-area-inset-bottom) !important;
    z-index: 1000;

    @media (prefers-color-scheme: dark) {
      background-color: #222;
    }

    @media (orientation: landscape) {

    }
  }

  .v-app-bar {
    margin-top: env(safe-area-inset-top) !important;
    box-sizing: initial;
  }

  .v-bottom-navigation {
    margin-bottom: env(safe-area-inset-bottom) !important;
  }

  main.v-content {
    margin-top: env(safe-area-inset-top) !important;
    margin-left: env(safe-area-inset-left) !important;
    margin-right: env(safe-area-inset-right) !important;
    margin-bottom: env(safe-area-inset-bottom) !important;
  }
}
